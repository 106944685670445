import React from 'react';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Card from '../components/card';
import styles from './about-henri-richard.module.css';

import useQuery from '../queries/about-henri-richard-query';

const AboutHenriRichard = () => {
  const {
    banner,
    userPicture,
    aboutHenriCard,
    aboutHenriCardP2,
    aboutHenriCardP3,
  } = useQuery();

  return (
    <Layout>
      <SEO title="Henri Richard" />

      <div className={styles.aboutContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.aboutContentItem}>
            <Img
              className={styles.aboutContentImage}
              fluid={userPicture.media.fluid}
              alt="henri-richard"
            />
          </div>

          <div className={styles.aboutContentCard}>
            <Card
              title="ABOUT"
              subtitle="Henri Richard"
              desc={aboutHenriCard.description.description}
            />
          </div>
        </div>

        <div className={styles.container}>
          <Card desc={aboutHenriCardP2.description.description} />

          <Card desc={aboutHenriCardP3.description.description} />
          <div className={styles.bannerItem}>
            <Img
              className={styles.aboutImage}
              fluid={banner.media.fluid}
              alt="henri-richard"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutHenriRichard;
