import { graphql, useStaticQuery } from 'gatsby';

const useQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        banner: contentfulContentMedia(
          contentful_id: { eq: "kw0RKt5isjaV5EQaar6HX" }
        ) {
          media {
            fluid(quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        userPicture: contentfulContentMedia(
          contentful_id: { eq: "1KW1sN25SUrQOGoHbgntFa" }
        ) {
          media {
            fluid(quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        aboutHenriCard: contentfulCard(
          contentful_id: { eq: "4GXeDUl8tQHHq7bwDEG9Ev" }
        ) {
          title
          subtitle
          description {
            description
          }
        }
        aboutHenriCardP2: contentfulCard(
          contentful_id: { eq: "6J1woaJOQSuw9RGMFpfOtR" }
        ) {
          description {
            description
          }
        }
        aboutHenriCardP3: contentfulCard(
          contentful_id: { eq: "5LMjx3Ye8RM7a0G2B6fwWM" }
        ) {
          description {
            description
          }
        }
      }
    `,
  );
  return query;
};

export default useQuery;
